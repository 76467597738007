import Parse from 'parse';

import { multiEntityLoading } from '../mixins/loading-states';
/**
 * @class InstallationModule
 */
const SportsModule = {
  namespaced: true,
  state: {
    ...multiEntityLoading.state,
    sports: null,
    categories: null,
  },
  mutations: {
    ...multiEntityLoading.mutations,
    setSports(state, sports) {
      state.sports = sports;
    },
    setCategories(state, cat) {
      state.categories = cat;
    },
  },
  actions: {
    ...multiEntityLoading.actions,
    async loadSports({ commit }) {
      try {
        const sports = await new Parse.Query('Sport').find();
        commit('setSports', sports);
      } catch (e) {
        console.log(e);
      }
    },
    async loadCategories({ commit }) {
      try {
        const cats = await new Parse.Query('MountainProfileQuestionCategory').find();
        commit('setCategories', cats);
      } catch (e) {
        console.log(e);
      }
    },
  },
  getters: {
    ...multiEntityLoading.getters,
    sports: (state) => state.sports,
    categories: (state) => state.categories,
  },
};

export default SportsModule;
