import Parse from 'parse';

import { multiEntityLoading } from '../mixins/loading-states';
/**
 * @class InstallationModule
 */
const ToursModule = {
  namespaced: true,
  state: {
    ...multiEntityLoading.state,
    currentTour: null,
    newestTours: null,
    tourOfDay: null,
    tourOfDayPicture: null,
  },
  mutations: {
    ...multiEntityLoading.mutations,
    setCurrentTour(state, currentTour) {
      state.currentTour = currentTour;
    },
    setNewestTours(state, tours) {
      state.newestTours = tours;
    },
    setTourOfDay(state, tour) {
      state.tourOfDay = tour;
    },
    setTourOfDayPicture(state, pic) {
      state.tourOfDayPicture = pic;
    },
  },
  actions: {
    ...multiEntityLoading.actions,
    async loadCurrentTour({ commit }, id) {
      try {
        const tour = await new Parse.Query('Tour')
          .include('Sport')
          .include('mountainProfile')
          .get(id);
        if (tour) {
          commit('setCurrentTour', tour);
        }
      } catch (e) {
        console.log(e);
      }
    },
    async loadNewestTours({ commit }, sourceName) {
      try {
        const tours = await new Parse.Query('Tour').addDescending('createdAt').equalTo('sourceName', sourceName).limit(15).find();
        commit('setNewestTours', tours);
      } catch (e) {
        console.log(e);
      }
    },
    async loadTourOfDay({ commit }) {
      try {
        const tourOfDay = await new Parse.Query('Tour').equalTo('isTourOfDay', true).first();
        commit('setTourOfDay', tourOfDay);
        const pic = await tourOfDay.relation('media').query().find();
        commit('setTourOfDayPicture', pic[0].get('file').url());
      } catch (e) {
        console.log(e);
      }
    },
  },
  getters: {
    ...multiEntityLoading.getters,
    currentTour: (state) => state.currentTour,
    newestTours: (state) => state.newestTours,
    tourOfDay: (state) => state.tourOfDay,
    tourOfDayPicture: (state) => state.tourOfDayPicture,
  },
};

export default ToursModule;
