<template>
  <ion-header class="ion-no-border ion-no-shadow">
    <ion-toolbar>
      <ion-title>
        {{ $t('data_privacy') }}
      </ion-title>
      <ion-button slot="start" color="white" class="ion-no-shadow" @click="dismissModal">
        <ion-icon color="primary" class="font-bold text-xl" :icon="closeOutline"></ion-icon>
      </ion-button>
    </ion-toolbar>
  </ion-header>
  <ion-content class="max-h-full overflow-y-auto ion-padding">
    <div class=WordSection1>

      <p class=H1 align=center style='text-align:center;border:none'><span
          style='font-family:"Segoe UI",sans-serif'>Datenschutzhinweise<br>
Guida App</span></p>

      <p class=MsoNormal style='text-align:justify'><span style='font-family:"Segoe UI",sans-serif'>&nbsp;</span></p>

      <h1 style='margin-left:1.0cm;text-align:justify;text-indent:-1.0cm'><span
          style='font-size:10.0pt;font-family:"Segoe UI",sans-serif'>A.<span
          style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
</span></span><span style='font-size:10.0pt;font-family:"Segoe UI",sans-serif'>Vorwort</span></h1>

      <p class=MsoNormal style='text-align:justify;border:none'><span
          style='font-family:"Segoe UI",sans-serif'>Wir, die Guida GmbH, Friedhofstraße
26/1, 72644 Oberboihingen (nachfolgend: „Guida“, „das Unternehmen“, „wir“ oder
„uns“), nehmen den Schutz Ihrer personenbezogenen Daten ernst und möchten Sie
an dieser Stelle über den Datenschutz in unserem Unternehmen informieren.</span></p>

      <p class=MsoNormal style='text-align:justify;border:none'><span
          style='font-family:"Segoe UI",sans-serif'>Uns sind im Rahmen unserer
datenschutzrechtlichen Verantwortlichkeit durch das Inkrafttreten der
EU-Datenschutz-Grundverordnung (Verordnung (EU) 2016/679; nachfolgend:&nbsp;“DSGVO“)
zusätzliche Pflichten auferlegt worden, um den Schutz personenbezogener Daten
der von einer Verarbeitung betroffenen Person (wir sprechen Sie als betroffene
Person nachfolgend auch mit&nbsp;“Kunde“, „Nutzer“,&nbsp;“Sie“,&nbsp;“Ihnen“&nbsp;oder
„Betroffener“ an) sicherzustellen.</span></p>

      <p class=MsoNormal style='text-align:justify;border:none'><span
          style='font-family:"Segoe UI",sans-serif'>Soweit wir entweder alleine oder
gemeinsam mit anderen über die Zwecke und Mittel der Datenverarbeitung
entscheiden, umfasst dies vor allem die Pflicht, Sie transparent über Art,
Umfang, Zweck, Dauer und Rechtsgrundlage der Verarbeitung zu informieren
(vgl.&nbsp;Art. 13 und Art.&nbsp;14&nbsp;DSGVO). Mit dieser Erklärung
(nachfolgend: „Datenschutzhinweise“) informieren wir Sie darüber, in welcher
Weise Ihre personenbezogenen Daten von uns verarbeitet werden.</span></p>

      <p class=MsoNormal style='text-align:justify;border:none'><span
          style='font-family:"Segoe UI",sans-serif'>Um die für Sie relevanten Teile
finden zu können, beachten Sie bitte den nachfolgenden Überblick zur
Untergliederung der Datenschutzhinweise: &nbsp;</span></p>

      <h1 style='margin-left:1.0cm;text-align:justify;text-indent:-1.0cm'><span
          style='font-size:10.0pt;font-family:"Segoe UI",sans-serif'>B.<span
          style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
</span></span><span style='font-size:10.0pt;font-family:"Segoe UI",sans-serif'>Allgemeines</span></h1>

      <h2 style='margin-left:1.0cm;text-align:justify;text-indent:-1.0cm'><span
          style='font-size:10.0pt;font-family:"Segoe UI",sans-serif;font-style:normal'>1.<span
          style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
</span></span><span style='font-size:10.0pt;font-family:"Segoe UI",sans-serif;
font-style:normal'>Begriffsbestimmungen</span></h2>

      <p class=MsoNormal style='text-align:justify;border:none'><span
          style='font-family:"Segoe UI",sans-serif'>Nach dem Vorbild des&nbsp;Art. 4
DSGVO&nbsp;liegen dieser Datenschutzhinweise folgende Begriffsbestimmungen
zugrunde:</span></p>

      <p class=Liste1 style='margin-left:1.0cm;text-align:justify;text-indent:-1.0cm;
border:none'><span style='font-family:Symbol'>·<span style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
</span></span><span style='font-family:"Segoe UI",sans-serif'>„Personenbezogene
Daten“ (Art.&nbsp;4 Nr.&nbsp;1&nbsp;DSGVO) sind alle Informationen, die sich
auf eine identifizierte oder identifizierbare natürliche Person („Betroffener“)
beziehen. Identifizierbar ist eine Person, wenn sie direkt oder indirekt,
insbesondere mittels Zuordnung zu einer Kennung wie einem Namen, einer
Kennnummer, einer Online-Kennung, Standortdaten oder mithilfe von Informationen
zu ihren physischen, physiologischen, genetischen, psychischen,
wirtschaftlichen, kulturellen oder sozialen Identitätsmerkmalen identifiziert
werden kann. Die Identifizierbarkeit kann auch mittels einer Verknüpfung von
derartigen Informationen oder anderem Zusatzwissen gegeben sein. Auf das
Zustandekommen, die Form oder die Verkörperung der Informationen kommt es nicht
an (auch Fotos, Video- oder Tonaufnahmen können personenbezogene Daten
enthalten).</span></p>

      <p class=Liste1 style='margin-left:1.0cm;text-align:justify;text-indent:-1.0cm;
border:none'><span style='font-family:Symbol'>·<span style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
</span></span><span style='font-family:"Segoe UI",sans-serif'>„Verarbeiten“
(Art.&nbsp;4 Nr.&nbsp;2&nbsp;DSGVO) ist jeder Vorgang, bei dem mit
personenbezogenen Daten umgegangen wird, gleich ob mit oder ohne Hilfe
automatisierter (d.h. technikgestützter) Verfahren. Dies umfasst insbesondere
das Erheben (d.h. die Beschaffung), das Erfassen, die Organisation, das Ordnen,
die Speicherung, die Anpassung oder Veränderung, das Auslesen, das Abfragen,
die Verwendung, die Offenlegung durch Übermittlung, die Verbreitung oder
sonstige Bereitstellung, den Abgleich, die Verknüpfung, die Einschränkung, das
Löschen oder die Vernichtung von personenbezogenen Daten sowie die Änderung
einer Ziel- oder Zweckbestimmung, die einer Datenverarbeitung ursprünglich
zugrunde gelegt wurde.</span></p>

      <p class=Liste1 style='margin-left:1.0cm;text-align:justify;text-indent:-1.0cm;
border:none'><span style='font-family:Symbol'>·<span style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
</span></span><span style='font-family:"Segoe UI",sans-serif'>„Verantwortlicher“
(Art.&nbsp;4 Nr.&nbsp;7&nbsp;DSGVO) ist die natürliche oder juristische Person,
Behörde, Einrichtung oder andere Stelle, die allein oder gemeinsam mit anderen
über die Zwecke und Mittel der Verarbeitung von personenbezogenen Daten
entscheidet.</span></p>

      <p class=Liste1 style='margin-left:1.0cm;text-align:justify;text-indent:-1.0cm;
border:none'><span style='font-family:Symbol'>·<span style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
</span></span><span style='font-family:"Segoe UI",sans-serif'>„Dritter“
(Art.&nbsp;4 Nr.&nbsp;10&nbsp;DSGVO) ist jede natürliche oder juristische
Person, Behörde, Einrichtung oder andere Stelle außer dem Betroffenen, dem
Verantwortlichen, dem Auftragsverarbeiter und den Personen, die unter der
unmittelbaren Verantwortung des Verantwortlichen oder Auftragsverarbeiters
befugt sind, die personenbezogenen Daten zu verarbeiten; dazu gehören auch
andere konzernangehörige juristische Personen.</span></p>

      <p class=Liste1 style='margin-left:1.0cm;text-align:justify;text-indent:-1.0cm;
border:none'><span style='font-family:Symbol'>·<span style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
</span></span><span style='font-family:"Segoe UI",sans-serif'>„Auftragsverarbeiter“
(Art.&nbsp;4 Nr.&nbsp;8&nbsp;DSGVO) ist eine natürliche oder juristische
Person, Behörde, Einrichtung oder andere Stelle, die personenbezogene Daten im
Auftrag des Verantwortlichen, insbesondere gemäß dessen Weisungen, verarbeitet
(z. B. IT-Dienstleister). Im datenschutzrechtlichen Sinne ist ein
Auftragsverarbeiter insbesondere kein Dritter.</span></p>

      <p class=Liste1 style='margin-left:1.0cm;text-align:justify;text-indent:-1.0cm;
border:none'><span style='font-family:Symbol'>·<span style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
</span></span><span style='font-family:"Segoe UI",sans-serif'>„Einwilligung“
(Art.&nbsp;4 Nr.&nbsp;11 DSGVO) der betroffenen Person bezeichnet jede
freiwillig für den bestimmten Fall, in informierter Weise und
unmissverständlich abgegebene Willensbekundung in Form einer Erklärung oder
einer sonstigen eindeutigen bestätigenden Handlung, mit der die betroffene
Person zu verstehen gibt, dass sie mit der Verarbeitung der sie betreffenden
personenbezogenen Daten einverstanden ist.</span></p>

      <p class=MsoNormal style='text-align:justify;border:none'><span
          style='font-family:"Segoe UI",sans-serif'>&nbsp;</span></p>

      <h2 style='margin-left:1.0cm;text-align:justify;text-indent:-1.0cm'><span
          style='font-size:10.0pt;font-family:"Segoe UI",sans-serif;font-style:normal'>2.<span
          style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
</span></span><span style='font-size:10.0pt;font-family:"Segoe UI",sans-serif;
font-style:normal'>Änderung der Datenschutzhinweise&nbsp;</span></h2>

      <p class=MsoNormal style='text-align:justify;border:none'><span
          style='font-family:"Segoe UI",sans-serif'>(1) Im Rahmen der Fortentwicklung des
Datenschutzrechts sowie technologischer oder organisatorischer Veränderungen
werden unsere Datenschutzhinweise regelmäßig auf Anpassungs- oder
Ergänzungsbedarf hin überprüft. Über Änderungen werden Sie unterrichtet.</span></p>

      <p class=MsoNormal style='text-align:justify;border:none'><span
          style='font-family:"Segoe UI",sans-serif'>(2) Diese Datenschutzhinweise haben
den Stand von Juni 2023.</span></p>

      <p class=MsoNormal style='text-align:justify;border:none'><span
          style='font-family:"Segoe UI",sans-serif'>&nbsp;</span></p>

      <h2 style='margin-left:1.0cm;text-align:justify;text-indent:-1.0cm'><span
          style='font-size:10.0pt;font-family:"Segoe UI",sans-serif;font-style:normal'>3.<span
          style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
</span></span><span style='font-size:10.0pt;font-family:"Segoe UI",sans-serif;
font-style:normal'>Keine Verpflichtung zur Bereitstellung personenbezogener
Daten</span></h2>

      <p class=MsoNormal style='text-align:justify;border:none'><span
          style='font-family:"Segoe UI",sans-serif'>Wir machen den Abschluss von
Verträgen mit uns nicht davon abhängig, dass Sie uns zuvor personenbezogene
Daten bereitstellen. Für Sie als Kunde besteht grundsätzlich auch keine
gesetzliche oder vertragliche Verpflichtung, uns Ihre personenbezogenen Daten
zur Verfügung zu stellen; es kann jedoch sein, dass wir bestimmte Angebote nur
eingeschränkt oder gar nicht erbringen können, wenn Sie die dafür
erforderlichen Daten nicht bereitstellen. Sofern dies im Rahmen der nachfolgend
vorgestellten, von uns angebotenen Produkte ausnahmsweise der Fall sein sollte,
werden Sie gesondert darauf hingewiesen.</span></p>

      <p class=MsoNormal style='text-align:justify;border:none'><span
          style='font-family:"Segoe UI",sans-serif'>&nbsp;</span></p>

      <h1 style='margin-left:1.0cm;text-align:justify;text-indent:-1.0cm'><span
          style='font-size:10.0pt;font-family:"Segoe UI",sans-serif'>C.<span
          style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
</span></span><span style='font-size:10.0pt;font-family:"Segoe UI",sans-serif'>Informationen
über die Verarbeitung Ihrer Daten</span></h1>

      <h2 style='margin-left:1.0cm;text-align:justify;text-indent:-1.0cm'><span
          style='font-size:10.0pt;font-family:"Segoe UI",sans-serif;font-style:normal'>1.<span
          style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
</span></span><span style='font-size:10.0pt;font-family:"Segoe UI",sans-serif;
font-style:normal'>Die Erhebung Sie betreffender personenbezogener Daten</span></h2>

      <p class=MsoNormal style='text-align:justify;border:none'><span
          style='font-family:"Segoe UI",sans-serif'>(1)&nbsp;Bei der Nutzung unserer App
werden von uns personenbezogene Daten über Sie erhoben.</span></p>

      <p class=MsoNormal style='text-align:justify;border:none'><span
          style='font-family:"Segoe UI",sans-serif'>(2)&nbsp;Personenbezogene Daten sind
alle Daten, die sich auf Ihre Person beziehen (siehe oben unter Allgemeines).
Beispielsweise handelt es sich bei Ihrem Namen, Ihrer Standortdaten, Ihre
IP-Adresse, die Gerätekennung, die SIM-Kartennummer, Ihrer Adresse sowie
E-Mail-Adresse um personenbezogene Daten, Ihr Fingerabdruck, Bilder, Filme,
Audioaufnahmen, aber auch Ihr Nutzerverhalten fällt in diese Kategorie.</span></p>

      <p class=MsoNormal style='text-align:justify;border:none'><span
          style='font-family:"Segoe UI",sans-serif'>&nbsp;</span></p>

      <h2 style='margin-left:1.0cm;text-align:justify;text-indent:-1.0cm'><span
          style='font-size:10.0pt;font-family:"Segoe UI",sans-serif;font-style:normal'>2.<span
          style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
</span></span><span style='font-size:10.0pt;font-family:"Segoe UI",sans-serif;
font-style:normal'>Rechtsgrundlagen der Datenverarbeitung</span></h2>

      <p class=MsoNormal style='text-align:justify;border:none'><span
          style='font-family:"Segoe UI",sans-serif'>(1) Von Gesetzes wegen ist im
Grundsatz jede Verarbeitung personenbezogener Daten verboten und nur dann
erlaubt, wenn die Datenverarbeitung unter einen der folgenden
Rechtfertigungstatbestände fällt:</span></p>

      <p class=Liste1 style='margin-left:1.0cm;text-align:justify;text-indent:-1.0cm;
border:none'><span style='font-family:Symbol'>·<span style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
</span></span><span style='font-family:"Segoe UI",sans-serif'>Art.&nbsp;6
Abs.&nbsp;1 S.&nbsp;1&nbsp;lit. a DSGVO&nbsp;(„Einwilligung“): Wenn der
Betroffene freiwillig, in informierter Weise und unmissverständlich durch eine
Erklärung oder eine sonstige eindeutige bestätigende Handlung zu verstehen
gegeben hat, dass er mit der Verarbeitung der ihn betreffenden
personenbezogenen Daten für einen oder mehrere bestimmte Zwecke einverstanden
ist;</span></p>

      <p class=Liste1 style='margin-left:1.0cm;text-align:justify;text-indent:-1.0cm;
border:none'><span style='font-family:Symbol'>·<span style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
</span></span><span style='font-family:"Segoe UI",sans-serif'>Art.&nbsp;6
Abs.&nbsp;1 S.&nbsp;1 lit. b DSGVO: Wenn die Verarbeitung zur Erfüllung eines
Vertrags, dessen Vertragspartei der Betroffene ist, oder zur Durchführung vorvertraglicher
Maßnahmen erforderlich ist, die auf die Anfrage des Betroffenen erfolgen;</span></p>

      <p class=Liste1 style='margin-left:1.0cm;text-align:justify;text-indent:-1.0cm;
border:none'><span style='font-family:Symbol'>·<span style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
</span></span><span style='font-family:"Segoe UI",sans-serif'>Art.&nbsp;6
Abs.&nbsp;1&nbsp;S.&nbsp;1 lit. c DSGVO: Wenn die Verarbeitung zur Erfüllung
einer rechtlichen Verpflichtung erforderlich ist, der der Verantwortliche
unterliegt (z.B. eine gesetzliche Aufbewahrungspflicht);</span></p>

      <p class=Liste1 style='margin-left:1.0cm;text-align:justify;text-indent:-1.0cm;
border:none'><span style='font-family:Symbol'>·<span style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
</span></span><span style='font-family:"Segoe UI",sans-serif'>Art.&nbsp;5
Abs.&nbsp;1&nbsp;S.&nbsp;1 lit. d DSGVO: Wenn die Verarbeitung erforderlich
ist, um lebenswichtige Interessen des Betroffenen oder einer anderen
natürlichen Person zu schützen;</span></p>

      <p class=Liste1 style='margin-left:1.0cm;text-align:justify;text-indent:-1.0cm;
border:none'><span style='font-family:Symbol'>·<span style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
</span></span><span style='font-family:"Segoe UI",sans-serif'>Art.&nbsp;6
Abs.&nbsp;1&nbsp;S.&nbsp;1 lit. e DSGVO: Wenn die Verarbeitung für die
Wahrnehmung einer Aufgabe erforderlich ist, die im öffentlichen Interesse liegt
oder in Ausübung öffentlicher Gewalt erfolgt, die dem Verantwortlichen
übertragen wurde oder</span></p>

      <p class=Liste1 style='margin-left:1.0cm;text-align:justify;text-indent:-1.0cm;
border:none'><span style='font-family:Symbol'>·<span style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
</span></span><span style='font-family:"Segoe UI",sans-serif'>Art.&nbsp;6
Abs.&nbsp;1&nbsp;S.&nbsp;1 lit. f DSGVO&nbsp;(„Berechtigte Interessen“): Wenn
die Verarbeitung zur Wahrung berechtigter (insbesondere rechtlicher oder
wirtschaftlicher) Interessen des Verantwortlichen oder eines Dritten
erforderlich ist, sofern nicht die gegenläufigen Interessen oder Rechte des
Betroffenen überwiegen (insbesondere dann, wenn es sich dabei um einen
Minderjährigen handelt).</span></p>

      <p class=MsoNormal style='text-align:justify;border:none'><span
          style='font-family:"Segoe UI",sans-serif'>(2) Für die von uns vorgenommenen
Verarbeitungsvorgänge geben wir im Folgenden jeweils die anwendbare
Rechtsgrundlage an. Eine Verarbeitung kann auch auf mehreren Rechtsgrundlagen
beruhen.</span></p>

      <p class=MsoNormal style='text-align:justify;border:none'><span
          style='font-family:"Segoe UI",sans-serif'>&nbsp;</span></p>

      <h2 style='margin-left:1.0cm;text-align:justify;text-indent:-1.0cm'><span
          style='font-size:10.0pt;font-family:"Segoe UI",sans-serif;font-style:normal'>3.<span
          style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
</span></span><span style='font-size:10.0pt;font-family:"Segoe UI",sans-serif;
font-style:normal'>Die beim Download erhobenen Daten</span></h2>

      <p class=MsoNormal style='text-align:justify;border:none'><span
          style='font-family:"Segoe UI",sans-serif'>(1) Beim Download dieser App werden
bestimmte dafür erforderlichen Daten zu Ihrer Person an den entsprechenden App
Store (z.B. Apple App Store oder Google Play) übermittelt.</span></p>

      <p class=MsoNormal style='text-align:justify;border:none'><span
          style='font-family:"Segoe UI",sans-serif'>(2) Insbesondere werden beim
Herunterladen die E-Mail-Adresse, der Nutzername, die Kundennummer des
herunterladenden Accounts, die individuelle Gerätekennziffer,
Zahlungsinformationen sowie der Zeitpunkt des Downloads an den App Store
übertragen werden.</span></p>

      <p class=MsoNormal style='text-align:justify;border:none'><span
          style='font-family:"Segoe UI",sans-serif'>(3) Auf die Erhebung und Verarbeitung
dieser Daten haben wir keinen Einfluss, sie erfolgt vielmehr ausschließlich
durch den von Ihnen ausgewählten App Store. Dementsprechend sind wir für diese
Erhebung und Verarbeitung nicht verantwortlich; die Verantwortung dafür liegt
allein beim App Store.</span></p>

      <p class=MsoNormal style='text-align:justify;border:none'><span
          style='font-family:"Segoe UI",sans-serif'>&nbsp;</span></p>

      <h2 style='margin-left:1.0cm;text-align:justify;text-indent:-1.0cm'><span
          style='font-size:10.0pt;font-family:"Segoe UI",sans-serif;font-style:normal'>4.<span
          style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
</span></span><span style='font-size:10.0pt;font-family:"Segoe UI",sans-serif;
font-style:normal'>Bei der Nutzung erhobenen Daten</span></h2>

      <p class=MsoNormal style='text-align:justify;border:none'><span
          style='font-family:"Segoe UI",sans-serif'>(1)&nbsp;Die Vorzüge unserer App
können wir ihnen zwangsläufig nur zur Verfügung stellen, wenn bei der Nutzung
von uns bestimmte, für den App-Betrieb erforderliche Daten zu Ihrer Person
erhoben werden.</span></p>

      <p class=MsoNormal style='text-align:justify;border:none'><span
          style='font-family:"Segoe UI",sans-serif'>(2) Wir erheben diese Daten nur, wenn
dies für die Erfüllung des Vertrags zwischen Ihnen und uns erforderlich ist
(Art.&nbsp;6 Abs.&nbsp;1 lit. b DSGVO). Ferner erheben wir diese Daten, wenn
dies für die Funktionsfähigkeit der App erforderlich ist und Ihr Interesse am
Schutz Ihrer personenbezogenen Daten nicht überwiegt (Art.&nbsp;6 Abs.&nbsp;1
lit. f DSGVO).</span></p>

      <p class=MsoNormal style='text-align:justify;border:none'><span
          style='font-family:"Segoe UI",sans-serif'>(3)&nbsp;Wir erheben und verarbeiten
folgende Daten von Ihnen:</span></p>

      <p class=Liste1 style='margin-left:1.0cm;text-align:justify;text-indent:-1.0cm;
border:none'><span style='font-family:Symbol'>·<span style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
</span></span><span style='font-family:"Segoe UI",sans-serif'>Geräteinformationen:
Zu den Zugriffsdaten gehören die IP-Adresse, Geräte-ID, Geräteart,
gerätespezifische Einstellungen und App-Einstellungen sowie App-Eigenschaften,
das Datum und die Uhrzeit des Abrufs, Zeitzone die übertragene Datenmenge und
die Meldung, ob der Datenaustausch vollständig war, Absturz der App, Browserart
und Betriebssystem. Diese Zugriffsdaten werden verarbeitet, um den Betrieb der App
technischen zu ermöglichen.</span></p>

      <p class=Liste1 style='margin-left:1.0cm;text-align:justify;text-indent:-1.0cm;
border:none'><span style='font-family:Symbol'>·<span style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
</span></span><span style='font-family:"Segoe UI",sans-serif'>Daten, die Sie
uns zur Verfügung stellen: Für die Nutzung der App ist die Erstellung eines
Nutzerkontos erforderlich. Dafür geben Sie mindestens Ihre E-Mail-Adresse als Anmeldenamen
an.</span></p>

      <p class=Liste1 style='margin-left:1.0cm;text-align:justify;text-indent:-1.0cm;
border:none'><span style='font-family:Symbol'>·<span style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
</span></span><span style='font-family:"Segoe UI",sans-serif'>Informationen mit
Ihrer Einwilligung: Daten zu z.B. Strecken- bzw. Weglänge, Höhenprofil, Fahrtechnik
(MTB) bzw. Trittsicherheit (Wandern), Fitnesslevel und sonstige Informationen
(z.B. GPS-Standortdaten) verarbeiten wir, wenn Sie uns dies gestatten.</span></p>

      <p class=Liste1 style='margin-left:1.0cm;text-align:justify;text-indent:-1.0cm;
border:none'><span style='font-family:Symbol'>·<span style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
</span></span><span style='font-family:"Segoe UI",sans-serif'>Kontaktformulardaten:
Bei der Nutzung von Kontaktformularen werden die dadurch übermittelten Daten
verarbeitet (z.B. Geschlecht, Name und Vorname, Anschrift, Firma,
E-Mail-Adresse und der Zeitpunkt der Übermittlung).</span></p>

      <p class=Liste1 style='text-align:justify;text-indent:0cm;border:none'><span
          style='font-family:"Segoe UI",sans-serif'>(4)&nbsp;Erstellung eines
Nutzerprofils</span></p>

      <p class=Liste1 style='margin-left:1.0cm;text-align:justify;text-indent:-1.0cm;
border:none'><span style='font-family:Symbol'>·<span style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
</span></span><span style='font-family:"Segoe UI",sans-serif'>Kategorien von
Daten: Personenbezogene Daten, die wir für die Erstellung eines Nutzerprofils
zwingend benötigen: E-Mail-Adresse; (personenbezogene) Daten, die Sie uns
freiwillig überlassen: Vor- und Nachname sowie Angaben zu z.B. Strecken- bzw.
Weglänge, Höhenprofil, Fahrtechnik (MTB) bzw. Trittsicherheit (Wandern),
Fitnesslevel.</span></p>

      <p class=Liste1 style='margin-left:1.0cm;text-align:justify;text-indent:-1.0cm;
border:none'><span style='font-family:Symbol'>·<span style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
</span></span><span style='font-family:"Segoe UI",sans-serif'>Zweck der
Verarbeitung: Mithilfe dieser Funktion können Sie ein Nutzerprofil erstellen,
damit wir Ihnen auf Ihre Fähigkeiten zugeschnittene Touren empfehlen können.</span></p>

      <p class=Liste1 style='margin-left:1.0cm;text-align:justify;text-indent:-1.0cm;
border:none'><span style='font-family:Symbol'>·<span style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
</span></span><span style='font-family:"Segoe UI",sans-serif'>Rechtsgrundlage:
Einwilligung (Art. 6 Abs. 1 lit. a DSGVO). Sie können Ihre Einwilligung
jederzeit widerrufen.</span></p>

      <p class=Liste1 style='margin-left:1.0cm;text-align:justify;text-indent:-1.0cm;
border:none'><span style='font-family:Symbol'>·<span style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
</span></span><span style='font-family:"Segoe UI",sans-serif'>Speicherdauer:
Ihre Daten werden gespeichert, bis der Zweck ihrer ursprünglichen Erhebung
entfällt. Die Speicherdauer Ihrer Daten zu diesem Zweck ist auf die Dauer der
Verarbeitung nach Abschnitt&nbsp;7 beschränkt.</span></p>

      <p class=MsoNormal style='text-align:justify;border:none'><span
          style='font-family:"Segoe UI",sans-serif'>&nbsp;</span></p>

      <h2 style='margin-left:1.0cm;text-align:justify;text-indent:-1.0cm'><span
          style='font-size:10.0pt;font-family:"Segoe UI",sans-serif;font-style:normal'>5.<span
          style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
</span></span><span style='font-size:10.0pt;font-family:"Segoe UI",sans-serif;
font-style:normal'>Einsatz von Cookies</span></h2>

      <p class=MsoNormal style='text-align:justify;border:none'><span
          style='font-family:"Segoe UI",sans-serif'>(1)&nbsp;Beim Betrieb unserer App
nutzen wir Cookies. Bei Cookies handelt es sich um kleine Textdateien, die auf
dem Gerätespeicher Ihres mobilen Endgerätes abgelegt und der von Ihnen
verwendeten mobilen App zugeordnet gespeichert werden und durch welche der
Stelle, die das Cookie setzt, bestimmte Informationen zufließen. Cookies können
keine Programme ausführen oder Viren auf Ihren Computer übertragen und daher
keine Schäden anrichten. Sie dienen dazu, unsere App insgesamt
nutzerfreundlicher und effektiver, also für Sie angenehmer zu machen.</span></p>

      <p class=MsoNormal style='text-align:justify;border:none'><span
          style='font-family:"Segoe UI",sans-serif'>(2) Cookies können Daten enthalten,
die eine Wiedererkennung des genutzten Geräts möglich machen. Teilweise
enthalten Cookies aber auch lediglich Informationen zu bestimmten
Einstellungen, die nicht personenbeziehbar sind. Cookies können einen Nutzer
aber nicht direkt identifizieren.</span></p>

      <p class=MsoNormal style='text-align:justify;border:none'><span
          style='font-family:"Segoe UI",sans-serif'>(3)&nbsp;Man unterscheidet zwischen
Session-Cookies, die wieder gelöscht werden, sobald Sie die App schließen und
permanenten Cookies, die über die einzelne Sitzung hinaus gespeichert werden.
Hinsichtlich ihrer Funktion unterscheidet man bei Cookies wiederum zwischen:</span></p>

      <p class=Liste1 style='margin-left:1.0cm;text-align:justify;text-indent:-1.0cm;
border:none'><span style='font-family:Symbol'>·<span style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
</span></span><span style='font-family:"Segoe UI",sans-serif'>Technical
Cookies: Diese sind zwingend erforderlich, um sich innerhalb der App zu
bewegen, grundlegende Funktionen zu nutzen und die Sicherheit der App zu
gewährleisten; sie sammeln weder Informationen über Sie zu Marketingzwecken
noch speichern sie, welche Webseiten Sie besucht haben;</span></p>

      <p class=Liste1 style='margin-left:1.0cm;text-align:justify;text-indent:-1.0cm;
border:none'><span style='font-family:Symbol'>·<span style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
</span></span><span style='font-family:"Segoe UI",sans-serif'>Performance
Cookies: Diese sammeln Informationen darüber, wie Sie unsere App nutzen, welche
Seiten Sie besuchen und z.B. ob Fehler bei der Appnutzung auftreten; sie
sammeln keine Informationen, die Sie identifizieren könnten – alle gesammelten
Informationen sind anonym und werden nur verwendet, um unsere App zu verbessern
und herauszufinden, was unsere Nutzer interessiert;</span></p>

      <p class=Liste1 style='margin-left:1.0cm;text-align:justify;text-indent:-1.0cm;
border:none'><span style='font-family:Symbol'>·<span style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
</span></span><span style='font-family:"Segoe UI",sans-serif'>Advertising
Cookies, Targeting Cookies: Diese dienen dazu, dem Appnutzer bedarfsgerechte
Werbung innerhalb der App oder Angebote von Dritten anzubieten und die
Effektivität dieser Angebote zu messen; Advertising und Targeting Cookies werden
maximal 13&nbsp;Monate lang gespeichert;</span></p>

      <p class=Liste1 style='margin-left:1.0cm;text-align:justify;text-indent:-1.0cm;
border:none'><span style='font-family:Symbol'>·<span style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
</span></span><span style='font-family:"Segoe UI",sans-serif'>Sharing Cookies:
Diese dienen dazu, die Interaktivität unserer App mit anderen Diensten (z.B.
sozialen Netzwerken) zu verbessern; Sharing Cookies werden maximal
13&nbsp;Monate lang gespeichert.</span></p>

      <p class=MsoNormal style='text-align:justify;border:none'><span
          style='font-family:"Segoe UI",sans-serif'>(4)&nbsp;Jeder Einsatz von Cookies,
der nicht zwingend technisch erforderlich ist, stellt eine Datenverarbeitung
dar, die nur mit einer ausdrücklichen und aktiven Einwilligung Ihrerseits gem.
Art.&nbsp;6 Abs.&nbsp;1 S.&nbsp;1 lit.&nbsp;a DSGVO&nbsp;erlaubt ist. Dies gilt
insbesondere für die Verwendung von Advertising, Targeting oder Sharing
Cookies.&nbsp;Darüber hinaus geben wir Ihre durch Cookies verarbeiteten
personenbezogenen Daten nur an Dritte weiter, wenn Sie nach Art.&nbsp;6
Abs.&nbsp;1&nbsp;S.&nbsp;1 lit. a DSGVO&nbsp;eine ausdrückliche Einwilligung
dazu erteilt haben.&nbsp;</span></p>

      <p class=MsoNormal style='text-align:justify;border:none'><span
          style='font-family:"Segoe UI",sans-serif'>&nbsp;</span></p>

      <h2 style='margin-left:1.0cm;text-align:justify;text-indent:-1.0cm'><span
          style='font-size:10.0pt;font-family:"Segoe UI",sans-serif;font-style:normal'>6.<span
          style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
</span></span><span style='font-size:10.0pt;font-family:"Segoe UI",sans-serif;
font-style:normal'>Zeitraum der Datenspeicherung</span></h2>

      <p class=MsoNormal style='text-align:justify;border:none'><span
          style='font-family:"Segoe UI",sans-serif'>(1)&nbsp;Wir löschen Ihre
personenbezogenen Daten, sobald sie für die Zwecke, für die wir sie nach den
erhoben oder verwendet haben (siehe C. 4., 5., 6.), nicht mehr erforderlich
sind. In der Regel speichern wir Ihre personenbezogenen Daten für die Dauer des
Nutzungs- bzw. des Vertragsverhältnisses über die App. Eine Speicherung Ihrer
Daten erfolgt grundsätzlich nur auf unseren Servern in Deutschland,
vorbehaltlich einer ggf. erfolgenden Weitergabe nach den Regelungen in F. 1.,
2. und 3.</span></p>

      <p class=MsoNormal style='text-align:justify;border:none'><span
          style='font-family:"Segoe UI",sans-serif'>(2)&nbsp;Eine Speicherung kann jedoch
über die angegebene Zeit hinaus im Falle einer (drohenden) Rechtsstreitigkeit
mit Ihnen oder eines sonstigen rechtlichen Verfahrens erfolgen.</span></p>

      <p class=MsoNormal style='text-align:justify;border:none'><span
          style='font-family:"Segoe UI",sans-serif'>(3)&nbsp;Von uns eingesetzte Dritte
(siehe F. 1.) werden Ihre Daten auf deren System so lange speichern, wie es im
Zusammenhang mit der Erbringung der Leistung für uns entsprechend dem
jeweiligen Auftrag erforderlich ist.</span></p>

      <p class=MsoNormal style='text-align:justify;border:none'><span
          style='font-family:"Segoe UI",sans-serif'>(4) Rechtliche Vorgaben zur
Aufbewahrung und Löschung personenbezogener Daten bleiben von Vorstehendem
unberührt (z.B. §&nbsp;257 HGB oder §&nbsp;147 AO). Wenn die durch die
gesetzlichen Vorschriften vorgeschriebene Speicherfrist abläuft, erfolgt eine
Sperrung oder Löschung der personenbezogenen Daten, es sei denn, dass eine
weitere Speicherung durch uns erforderlich ist und dafür eine Rechtsgrundlage
besteht.</span></p>

      <p class=MsoNormal style='text-align:justify;border:none'><span
          style='font-family:"Segoe UI",sans-serif'>&nbsp;</span></p>

      <h2 style='margin-left:1.0cm;text-align:justify;text-indent:-1.0cm'><span
          style='font-size:10.0pt;font-family:"Segoe UI",sans-serif;font-style:normal'>7.<span
          style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
</span></span><span style='font-size:10.0pt;font-family:"Segoe UI",sans-serif;
font-style:normal'>Datensicherheit</span></h2>

      <p class=MsoNormal style='text-align:justify;border:none'><span
          style='font-family:"Segoe UI",sans-serif'>(1)&nbsp;Wir bedienen uns geeigneter
technischer und organisatorischer Sicherheitsmaßnahmen, um Ihre Daten gegen
zufällige oder vorsätzliche Manipulationen, teilweisen oder vollständigen
Verlust, Zerstörung oder gegen den unbefugten Zugriff Dritter zu schützen unter
Berücksichtigung des Stands der Technik, der Implementierungskosten und der
Natur, des Umfangs, des Kontextes und des Zwecks der Verarbeitung sowie der
bestehenden Risiken einer Datenpanne (inklusive von deren Wahrscheinlichkeit
und Auswirkungen) für den Betroffenen. Unsere Sicherheitsmaßnahmen werden
entsprechend der technologischen Entwicklung fortlaufend verbessert.</span></p>

      <p class=MsoNormal style='text-align:justify;border:none'><span
          style='font-family:"Segoe UI",sans-serif'>(2) Nähere Informationen hierzu
erteilen wir Ihnen auf Anfrage gerne. Wenden Sie sich hierzu bitte an uns unter
den angegebenen Kontaktdaten (siehe D. 1.).</span></p>

      <p class=MsoNormal style='text-align:justify;border:none'><span
          style='font-family:"Segoe UI",sans-serif'>&nbsp;</span></p>

      <h2 style='margin-left:1.0cm;text-align:justify;text-indent:-1.0cm'><span
          style='font-size:10.0pt;font-family:"Segoe UI",sans-serif;font-style:normal'>8.<span
          style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
</span></span><span style='font-size:10.0pt;font-family:"Segoe UI",sans-serif;
font-style:normal'>Zweckänderung</span></h2>

      <p class=MsoNormal style='text-align:justify;border:none'><span
          style='font-family:"Segoe UI",sans-serif'>(1) Verarbeitungen Ihrer
personenbezogenen Daten zu anderen als den beschriebenen Zwecken erfolgen nur,
soweit eine Rechtsvorschrift dies erlaubt oder Sie in den geänderten Zweck der
Datenverarbeitung eingewilligt haben.</span></p>

      <p class=MsoNormal style='text-align:justify;border:none'><span
          style='font-family:"Segoe UI",sans-serif'>(2)&nbsp;Im Falle einer
Weiterverarbeitung zu anderen Zwecken als denen, für den die Daten ursprünglich
erhoben worden sind, informieren wir Sie vor der Weiterverarbeitung über diese
anderen Zwecke und stellen Ihnen sämtliche weitere hierfür maßgeblichen
Informationen zur Verfügung.</span></p>

      <p class=MsoNormal style='text-align:justify;border:none'><span
          style='font-family:"Segoe UI",sans-serif'>&nbsp;</span></p>

      <h1 style='margin-left:1.0cm;text-align:justify;text-indent:-1.0cm'><span
          style='font-size:10.0pt;font-family:"Segoe UI",sans-serif'>D.<span
          style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
</span></span><span style='font-size:10.0pt;font-family:"Segoe UI",sans-serif'>Verantwortlichkeit
für Ihre Daten und Kontakte</span></h1>

      <h2 style='margin-left:1.0cm;text-align:justify;text-indent:-1.0cm'><span
          style='font-size:10.0pt;font-family:"Segoe UI",sans-serif;font-style:normal'>1.<span
          style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
</span></span><span style='font-size:10.0pt;font-family:"Segoe UI",sans-serif;
font-style:normal'>Verantwortlicher und Kontaktdaten</span></h2>

      <p class=MsoNormal style='text-align:justify;border:none'><span
          style='font-family:"Segoe UI",sans-serif'>(1) Die für die Verarbeitung Ihrer
personenbezogenen Daten verantwortliche Stelle im Sinne des Art.&nbsp;4
Nr.&nbsp;7&nbsp;DSGVO&nbsp;sind wir</span></p>

      <p class=MsoNormal style='border:none'><span style='font-family:"Segoe UI",sans-serif'>Guida
GmbH<br>
Friedhofstraße 26/1<br>
72644 Oberboihingen<br>
Telefon: +49 (0) 173 5423349<br>
E-Mail: </span><a href="mailto:datenschutz@guida-sa.de"><span style='font-family:
"Segoe UI",sans-serif'>datenschutz@guida-sa.de</span></a><span
          style='font-family:"Segoe UI",sans-serif'> </span></p>

      <p class=MsoNormal style='text-align:justify;border:none'><span
          style='font-family:"Segoe UI",sans-serif'>(3)&nbsp;Wenden Sie sich an diese
Kontaktstelle insbesondere, wenn Sie die Ihnen zustehenden Rechte, die unter
Kapitel G erläutert werden, uns gegenüber geltend machen wollen.</span></p>

      <p class=MsoNormal style='text-align:justify;border:none'><span
          style='font-family:"Segoe UI",sans-serif'>(4)&nbsp;Bei weiteren Fragen oder
Anmerkungen zur Erhebung und Verarbeitung Ihrer personenbezogenen Daten wenden
Sie sich ebenfalls an die vorbenannte Kontaktstelle.</span></p>

      <p class=MsoNormal style='text-align:justify;border:none'><span
          style='font-family:"Segoe UI",sans-serif'>&nbsp;</span></p>

      <h2 style='margin-left:1.0cm;text-align:justify;text-indent:-1.0cm'><span
          style='font-size:10.0pt;font-family:"Segoe UI",sans-serif;font-style:normal'>2.<span
          style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
</span></span><span style='font-size:10.0pt;font-family:"Segoe UI",sans-serif;
font-style:normal'>Datenerhebung bei der Kontaktaufnahme&nbsp;</span></h2>

      <p class=MsoNormal style='text-align:justify;border:none'><span
          style='font-family:"Segoe UI",sans-serif'>(1)&nbsp;Wenn Sie mit uns per E-Mail
oder über ein Kontaktformular mit uns Kontakt aufnehmen, dann werden Ihre
E-Mail-Adresse, Ihr Name und alle weiteren personenbezogenen Daten, die Sie im
Zuge der Kontaktaufnahme angegeben haben, von uns gespeichert, damit wir mit
Ihnen zur Beantwortung der Frage Kontakt aufnehmen können.</span></p>

      <p class=MsoNormal style='text-align:justify;border:none'><span
          style='font-family:"Segoe UI",sans-serif'>(2)&nbsp;Diese Daten löschen wir,
sobald die Speicherung nicht mehr erforderlich ist. Liegen gesetzliche
Aufbewahrungsfristen vor, bleiben die Daten zwar gespeichert, aber wir
schränken die Verarbeitung ein.</span></p>

      <p class=MsoNormal style='text-align:justify;border:none'><span
          style='font-family:"Segoe UI",sans-serif'>&nbsp;</span></p>

      <h1 style='margin-left:1.0cm;text-align:justify;text-indent:-1.0cm'><span
          style='font-size:10.0pt;font-family:"Segoe UI",sans-serif'>E.<span
          style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
</span></span><span style='font-size:10.0pt;font-family:"Segoe UI",sans-serif'>Datenverarbeitung
durch Dritte</span></h1>

      <h2 style='margin-left:1.0cm;text-align:justify;text-indent:-1.0cm'><span
          style='font-size:10.0pt;font-family:"Segoe UI",sans-serif;font-style:normal'>1.<span
          style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
</span></span><span style='font-size:10.0pt;font-family:"Segoe UI",sans-serif;
font-style:normal'>Auftragsdatenverarbeitung&nbsp;</span></h2>

      <p class=MsoNormal style='text-align:justify;border:none'><span
          style='font-family:"Segoe UI",sans-serif'>(1)&nbsp;Es kann vorkommen, dass für
einzelne Funktionen unserer App auf beauftragte Dienstleister zurückgegriffen
wird.). Diese werden nur nach unserer Weisung tätig und wurden iSv
Art.&nbsp;28&nbsp;DSGVO&nbsp;vertraglich dazu verpflichtet, die
datenschutzrechtlichen Bestimmungen einzuhalten.&nbsp;</span></p>

      <p class=MsoNormal style='text-align:justify;border:none'><span
          style='font-family:"Segoe UI",sans-serif'>(2)&nbsp;Folgende Kategorien von
Empfängern, bei denen es sich im Regelfall um Auftragsverarbeiter handelt,
erhalten ggf. Zugriff auf Ihre personenbezogenen Daten:</span></p>

      <p class=Liste1 style='margin-left:1.0cm;text-align:justify;text-indent:-1.0cm;
border:none'><span style='font-family:Symbol'>·<span style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
</span></span><span style='font-family:"Segoe UI",sans-serif'>Dienstleister für
den Betrieb unserer App und die Verarbeitung der durch die Systeme
gespeicherten oder übermittelten Daten (z.B. für Rechenzentrumsleistungen,
Zahlungsabwicklungen, IT-Sicherheit). Rechtsgrundlage für die Weitergabe ist
dann Art.&nbsp;6 Abs.&nbsp;1 S.&nbsp;1 lit. b oder lit. f DSGVO, soweit es sich
nicht um Auftragsverarbeiter handelt;</span></p>

      <p class=Liste1 style='margin-left:1.0cm;text-align:justify;text-indent:-1.0cm;
border:none'><span style='font-family:Symbol'>·<span style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
</span></span><span style='font-family:"Segoe UI",sans-serif'>Staatliche
Stellen/Behörden, soweit dies zur Erfüllung einer gesetzlichen Verpflichtung
erforderlich ist. Rechtsgrundlage für die Weitergabe ist dann&nbsp;Art. 6
Abs.&nbsp;1&nbsp;S.&nbsp;1 lit. c DSGVO;</span></p>

      <p class=Liste1 style='margin-left:1.0cm;text-align:justify;text-indent:-1.0cm;
border:none'><span style='font-family:Symbol'>·<span style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
</span></span><span style='font-family:"Segoe UI",sans-serif'>Zur Durchführung
unseres Geschäftsbetriebs eingesetzte Personen (z.B. Auditoren, Banken,
Versicherungen, Rechtsberater, Aufsichtsbehörden, Beteiligte bei
Unternehmenskäufen oder der Gründung von Gemeinschaftsunternehmen).
Rechtsgrundlage für die Weitergabe ist dann Art.&nbsp;6
Abs.&nbsp;1&nbsp;S.&nbsp;1 lit. b oder lit. f DSGVO.</span></p>

      <p class=MsoNormal style='text-align:justify;border:none'><span
          style='font-family:"Segoe UI",sans-serif'>(3)&nbsp;Darüber hinaus geben wir
Ihre personenbezogenen Daten nur an Dritte weiter, wenn Sie nach Art.&nbsp;6
Abs.&nbsp;1&nbsp;S.&nbsp;1 lit.&nbsp;a DSGVO&nbsp;eine ausdrückliche
Einwilligung dazu erteilt haben.</span></p>

      <p class=MsoNormal style='text-align:justify;border:none'><span
          style='font-family:"Segoe UI",sans-serif'>(4)&nbsp;Sofern personenbezogene
Daten von Ihnen durch uns an unsere Tochtergesellschaften weitergegeben werden
oder von unseren Tochtergesellschaften an uns weitergegeben werden (z.B. zu
werblichen Zwecken), geschieht dies aufgrund von bestehenden
Auftragsverarbeitungsverhältnissen.</span></p>

      <p class=MsoNormal style='text-align:justify;border:none'><span
          style='font-family:"Segoe UI",sans-serif'>&nbsp;</span></p>

      <h2 style='margin-left:1.0cm;text-align:justify;text-indent:-1.0cm'><span
          style='font-size:10.0pt;font-family:"Segoe UI",sans-serif;font-style:normal'>2.<span
          style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
</span></span><span style='font-size:10.0pt;font-family:"Segoe UI",sans-serif;
font-style:normal'>Voraussetzungen der Weitergabe von personenbezogenen Daten
an Drittländer&nbsp;</span></h2>

      <p class=MsoNormal style='text-align:justify;border:none'><span
          style='font-family:"Segoe UI",sans-serif'>(1)&nbsp;Im Rahmen unserer
Geschäftsbeziehungen können Ihre personenbezogenen Daten an Drittgesellschaften
weitergegeben oder offengelegt werden. Diese können sich auch außerhalb des
Europäischen Wirtschaftsraums (EWR), also in Drittländern, befinden. Eine
derartige Verarbeitung erfolgt ausschließlich zur Erfüllung der vertraglichen
und geschäftlichen Verpflichtungen und zur Pflege Ihrer Geschäftsbeziehung zu
uns. Über die jeweiligen Einzelheiten der Weitergabe unterrichten wir Sie
nachfolgend an den dafür relevanten Stellen.</span></p>

      <p class=MsoNormal style='text-align:justify;border:none'><span
          style='font-family:"Segoe UI",sans-serif'>(2)&nbsp;Einigen Drittländern
bescheinigt die Europäische Kommission durch sog. Angemessenheitsbeschlüsse
einen Datenschutz, der dem EWR-Standard vergleichbar ist (eine Liste dieser
Länder sowie eine Kopie der Angemessenheitsbeschlüsse erhalten Sie hier:
http://ec.europa.eu/justice/data-protection/international-transfers/adequacy/index_en.html).
In anderen Drittländern, in die ggf. personenbezogene Daten übertragen werden,
herrscht aber unter Umständen wegen fehlender gesetzlicher Bestimmungen kein
durchgängig hohes Datenschutzniveau. Soweit dies der Fall ist, achten wir
darauf, dass der Datenschutz ausreichend gewährleistet ist. Möglich ist dies
über bindende Unternehmensvorschriften, Standard-Vertragsklauseln der
Europäischen Kommission zum Schutz personenbezogener Daten, Zertifikate oder
anerkannte Verhaltenskodizes. Bitte wenden Sie sich an uns (siehe D. 1.), wenn
Sie hierzu nähere Informationen erhalten möchten.</span></p>

      <p class=MsoNormal style='text-align:justify;border:none'><span
          style='font-family:"Segoe UI",sans-serif'>&nbsp;</span></p>

      <h2 style='margin-left:1.0cm;text-align:justify;text-indent:-1.0cm'><span
          style='font-size:10.0pt;font-family:"Segoe UI",sans-serif;font-style:normal'>3.<span
          style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
</span></span><span style='font-size:10.0pt;font-family:"Segoe UI",sans-serif;
font-style:normal'>Gesetzliche Verpflichtung zur Übermittlung bestimmter Daten</span></h2>

      <p class=MsoNormal style='text-align:justify;border:none'><span
          style='font-family:"Segoe UI",sans-serif'>Wir können unter Umständen einer
besonderen gesetzlichen oder rechtlichen Verpflichtung unterliegen, die
rechtmäßig verarbeiteten personenbezogenen Daten für Dritte, insbesondere
öffentlichen Stellen, bereitzustellen (Art.&nbsp;6 Abs.&nbsp;1&nbsp;S.&nbsp;1
lit. c DSGVO).</span></p>

      <p class=MsoNormal style='text-align:justify;border:none'><span
          style='font-family:"Segoe UI",sans-serif'>&nbsp;</span></p>

      <h1 style='margin-left:1.0cm;text-align:justify;text-indent:-1.0cm'><span
          style='font-size:10.0pt;font-family:"Segoe UI",sans-serif'>F.<span
          style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
</span></span><span style='font-size:10.0pt;font-family:"Segoe UI",sans-serif'>Ihre
Rechte</span></h1>

      <h2 style='margin-left:1.0cm;text-align:justify;text-indent:-1.0cm'><span
          style='font-size:10.0pt;font-family:"Segoe UI",sans-serif;font-style:normal'>1.<span
          style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
</span></span><span style='font-size:10.0pt;font-family:"Segoe UI",sans-serif;
font-style:normal'>Auskunftsrecht</span></h2>

      <p class=MsoNormal style='text-align:justify;border:none'><span
          style='font-family:"Segoe UI",sans-serif'>(1)&nbsp;Sie gegenüber uns das Recht
im Umfang von Art.&nbsp;15 DSGVO, Auskunft über die Sie betreffenden
personenbezogenen Daten zu erhalten.</span></p>

      <p class=MsoNormal style='text-align:justify;border:none'><span
          style='font-family:"Segoe UI",sans-serif'>(2)&nbsp;Hierfür ist ein Antrag von
Ihnen erforderlich, der entweder per E-Mail oder postalisch an die oben
angegebenen Adressen (siehe D. 1.) zu senden ist.</span></p>

      <p class=MsoNormal style='text-align:justify;border:none'><span
          style='font-family:"Segoe UI",sans-serif'>&nbsp;</span></p>

      <h2 style='margin-left:1.0cm;text-align:justify;text-indent:-1.0cm'><span
          style='font-size:10.0pt;font-family:"Segoe UI",sans-serif;font-style:normal'>2.<span
          style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
</span></span><span style='font-size:10.0pt;font-family:"Segoe UI",sans-serif;
font-style:normal'>Recht auf Widerspruch gegen die Datenverarbeitung und
Widerruf der Einwilligung</span></h2>

      <p class=MsoNormal style='text-align:justify;border:none'><span
          style='font-family:"Segoe UI",sans-serif'>(1)&nbsp;Sie haben gemäß Art.&nbsp;21
DSGVO das Recht, jederzeit gegen die Verarbeitung Sie betreffender
personenbezogener Daten Widerspruch einzulegen. Wir werden die Verarbeitung
Ihrer personenbezogenen Daten einstellen, es sei denn, wir können zwingende
schutzwürdige Gründe für die Verarbeitung nachweisen, die Ihre Interessen,
Rechte und Freiheiten überwiegen, oder wenn die Verarbeitung der
Geltendmachung, Ausübung oder Verteidigung von Rechtsansprüchen dient.</span></p>

      <p class=MsoNormal style='text-align:justify;border:none'><span
          style='font-family:"Segoe UI",sans-serif'>(2)&nbsp;Gemäß Art.&nbsp;7
Abs.&nbsp;3&nbsp;DSGVO&nbsp;haben Sie das Recht Ihre einmal (auch vor der
Geltung der DSGVO, d.h. vor dem 25.5.2018) erteilte Einwilligung – also Ihr
freiwilliger, in informierter Weise und unmissverständlich durch eine Erklärung
oder eine sonstige eindeutige bestätigende Handlung verständlich gemachter
Willen, dass Sie mit der Verarbeitung der betreffenden personenbezogenen Daten
für einen oder mehrere bestimmte Zwecke einverstanden sind – jederzeit uns
gegenüber zu widerrufen, falls Sie eine solche erteilt haben. Dies hat zur
Folge, dass wir die Datenverarbeitung, die auf dieser Einwilligung beruhte, für
die Zukunft nicht mehr fortführen dürfen.</span></p>

      <p class=MsoNormal style='text-align:justify;border:none'><span
          style='font-family:"Segoe UI",sans-serif'>(3)&nbsp;Diesbezüglich wenden Sie
sich bitte an die oben angegebene Kontaktstelle (siehe D. 1.).</span></p>

      <p class=MsoNormal style='text-align:justify;border:none'><span
          style='font-family:"Segoe UI",sans-serif'>&nbsp;</span></p>

      <h2 style='margin-left:1.0cm;text-align:justify;text-indent:-1.0cm'><span
          style='font-size:10.0pt;font-family:"Segoe UI",sans-serif;font-style:normal'>3.<span
          style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
</span></span><span style='font-size:10.0pt;font-family:"Segoe UI",sans-serif;
font-style:normal'>Recht zur Berichtigung und Löschung</span></h2>

      <p class=MsoNormal style='text-align:justify;border:none'><span
          style='font-family:"Segoe UI",sans-serif'>(1)&nbsp;Insoweit Sie betreffende
personenbezogene Daten unrichtig sind, haben Sie gemäß Art.&nbsp;16 DSGVO das
Recht, von uns die unverzügliche Berichtigung zu verlangen. Mit einem
diesbezüglichen Antrag wenden Sie sich bitte an die oben angegebene
Kontaktstelle (siehe D. 1.).</span></p>

      <p class=MsoNormal style='text-align:justify;border:none'><span
          style='font-family:"Segoe UI",sans-serif'>(2)&nbsp;Unter den in Art.&nbsp;17
DSGVO genannten Voraussetzungen steht Ihnen das Recht zu, die Löschung Sie
betreffender personenbezogener Daten zu verlangen. Mit einem diesbezüglichen
Antrag wenden Sie sich bitte an die oben angegebene Kontaktstelle (siehe D.
1.). Das Recht auf Löschung steht Ihnen insbesondere zu, wenn die fraglichen
Daten für die Erhebungs- oder Verarbeitungszwecke nicht mehr notwendig sind,
wenn der Datenspeicherungszeitraum (siehe C. 7.) verstrichen ist, ein
Widerspruch vorliegt (siehe G. 2.), oder eine unrechtmäßige Verarbeitung
vorliegt.</span></p>

      <p class=MsoNormal style='text-align:justify;border:none'><span
          style='font-family:"Segoe UI",sans-serif'>&nbsp;</span></p>

      <h2 style='margin-left:1.0cm;text-align:justify;text-indent:-1.0cm'><span
          style='font-size:10.0pt;font-family:"Segoe UI",sans-serif;font-style:normal'>4.<span
          style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
</span></span><span style='font-size:10.0pt;font-family:"Segoe UI",sans-serif;
font-style:normal'>Recht auf Einschränkung der Verarbeitung</span></h2>

      <p class=MsoNormal style='text-align:justify;border:none'><span
          style='font-family:"Segoe UI",sans-serif'>(1)&nbsp;Nach Maßgabe des
Art.&nbsp;18 DSGVO haben Sie das Recht, von uns die Einschränkung der
Verarbeitung Ihrer personenbezogenen Daten zu verlangen.</span></p>

      <p class=MsoNormal style='text-align:justify;border:none'><span
          style='font-family:"Segoe UI",sans-serif'>(2) Mit einem diesbezüglichen Antrag
wenden Sie sich bitte an die oben angegebene Kontaktstelle (siehe D. 1.).&nbsp;</span></p>

      <p class=MsoNormal style='text-align:justify;border:none'><span
          style='font-family:"Segoe UI",sans-serif'>(3)&nbsp;Das Recht auf Einschränkung
der Verarbeitung steht Ihnen insbesondere zu, wenn die Richtigkeit der
personenbezogenen Daten zwischen Ihnen und uns umstritten ist; das Recht steht
Ihnen in diesem Fall für eine Zeitspanne zu, die für die Überprüfung der
Richtigkeit erfordert wird. Entsprechendes gilt, wenn die erfolgreiche Ausübung
eines Widerspruchsrechts (siehe G. 2.) zwischen Ihnen und uns noch umstritten
ist. Dieses Recht steht Ihnen außerdem insbesondere dann zu, wenn Ihnen ein
Recht auf Löschung zusteht (siehe G. 3.) und Sie anstelle einer Löschung eine
eingeschränkte Verarbeitung verlangen.</span></p>

      <p class=MsoNormal style='text-align:justify;border:none'><span
          style='font-family:"Segoe UI",sans-serif'>&nbsp;</span></p>

      <h2 style='margin-left:1.0cm;text-align:justify;text-indent:-1.0cm'><span
          style='font-size:10.0pt;font-family:"Segoe UI",sans-serif;font-style:normal'>5.<span
          style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
</span></span><span style='font-size:10.0pt;font-family:"Segoe UI",sans-serif;
font-style:normal'>Recht auf Datenübertragbarkeit&nbsp;</span></h2>

      <p class=MsoNormal style='text-align:justify;border:none'><span
          style='font-family:"Segoe UI",sans-serif'>(1)&nbsp;Nach Maßgabe des
Art.&nbsp;20 DSGVO haben Sie das Recht, von uns die Sie betreffenden personenbezogenen
Daten, die Sie uns bereitgestellt haben, in einem strukturierten, gängigen,
maschinenlesbaren Format nach Maßgabe zu erhalten.</span></p>

      <p class=MsoNormal style='text-align:justify;border:none'><span
          style='font-family:"Segoe UI",sans-serif'>(2) Mit einem diesbezüglichen Antrag
wenden Sie sich bitte an die oben angegebene Kontaktstelle (siehe D. 1.).&nbsp;</span></p>

      <p class=MsoNormal style='text-align:justify;border:none'><span
          style='font-family:"Segoe UI",sans-serif'>&nbsp;</span></p>

      <h2 style='margin-left:1.0cm;text-align:justify;text-indent:-1.0cm'><span
          style='font-size:10.0pt;font-family:"Segoe UI",sans-serif;font-style:normal'>6.<span
          style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
</span></span><span style='font-size:10.0pt;font-family:"Segoe UI",sans-serif;
font-style:normal'>Recht auf Beschwerde bei der Aufsichtsbehörde</span></h2>

      <p class=MsoNormal style='text-align:justify;border:none'><span
          style='font-family:"Segoe UI",sans-serif'>(1)&nbsp;Sie haben gemäß Art.&nbsp;77
DSGVO das Recht, sich über die Erhebung und Verarbeitung Ihrer
personenbezogenen Daten bei der zuständigen Aufsichtsbehörde zu beschweren.</span></p>

      <p class=MsoNormal style='text-align:justify;border:none'><span
          style='font-family:"Segoe UI",sans-serif'>(2)&nbsp;Die zuständige
Aufsichtsbehörde erreichen Sie unter folgenden Kontaktdaten: </span></p>

      <p class=MsoNormal style='text-align:justify;border:none'><span
          style='font-family:"Segoe UI",sans-serif'>Der Landesbeauftragte für den
Datenschutz und die Informationsfreiheit in Baden-Württemberg (LfDI),
erreichbar unter Königstraße 10a, 70173 Stuttgart, Deutschland, Telefon: +49
711 615541-0, Fax: +49 711 615541-15, E-Mail: poststelle@lfdi.bwl.de, Internet:
www.baden-wuerttemberg.datenschutz.de.</span></p>

      <p class=MsoNormal style='text-align:justify;border:none'><span
          style='font-family:"Segoe UI",sans-serif'>&nbsp;</span></p>

      <p class=MsoNormal style='text-align:justify'><span style='font-family:"Segoe UI",sans-serif'>&nbsp;</span></p>

      <p>Bild auf der Homeseite: Foto von Daniel Seßler auf Unsplash</p>
      <p>Version: 1.1.3</p>

    </div>
    <v-idle @idle="dismissModal" :duration="50" :events="['touchend']" class="hidden" />
  </ion-content>
</template>

<script>
import { closeOutline } from 'ionicons/icons';
import { IonContent, IonButton, IonIcon } from '@ionic/vue';
import { modalController, IonHeader, IonTitle, IonToolbar } from '@ionic/vue';
export default {
  name: 'DatenschutzModal',
  components: { IonIcon, IonContent, IonButton, IonHeader, IonTitle, IonToolbar },
  props: {
    current: {
      type: Object,
      default: null,
    },

  },
  data() {
    return {
      closeOutline,
    };
  },
  computed: {},
  mounted() {},
  unmounted() {},
  methods: {
    async dismissModal() {
      await modalController.dismiss();
    },
  },
};
</script>

<style scoped>
p.MsoNormal, li.MsoNormal, div.MsoNormal
{margin-top:6.0pt;
  margin-right:0cm;
  margin-bottom:6.0pt;
  margin-left:0cm;
  text-autospace:none;
  font-size:10.0pt;
  font-family:"Arial",sans-serif;
  color:black;}
h1
{mso-style-link:"Überschrift 1 Zchn";
  margin-top:11.9pt;
  margin-right:0cm;
  margin-bottom:3.1pt;
  margin-left:0cm;
  page-break-after:avoid;
  text-autospace:none;
  font-size:16.0pt;
  font-family:Helvetica;
  color:black;}
h2
{mso-style-link:"Überschrift 2 Zchn";
  margin-top:11.9pt;
  margin-right:0cm;
  margin-bottom:3.1pt;
  margin-left:0cm;
  page-break-after:avoid;
  text-autospace:none;
  font-size:14.0pt;
  font-family:Helvetica;
  color:black;
  font-style:italic;}
p.MsoToc1, li.MsoToc1, div.MsoToc1
{margin-top:6.0pt;
  margin-right:0cm;
  margin-bottom:5.0pt;
  margin-left:0cm;
  text-autospace:none;
  font-size:10.0pt;
  font-family:"Arial",sans-serif;
  color:black;}
p.MsoToc2, li.MsoToc2, div.MsoToc2
{margin-top:6.0pt;
  margin-right:0cm;
  margin-bottom:5.0pt;
  margin-left:10.0pt;
  text-autospace:none;
  font-size:10.0pt;
  font-family:"Arial",sans-serif;
  color:black;}
p.MsoHeader, li.MsoHeader, div.MsoHeader
{mso-style-link:"Kopfzeile Zchn";
  margin:0cm;
  text-autospace:none;
  font-size:10.0pt;
  font-family:"Arial",sans-serif;
  color:black;}
a:link, span.MsoHyperlink
{color:#0563C1;
  text-decoration:underline;}
p.MsoTocHeading, li.MsoTocHeading, div.MsoTocHeading
{margin-top:12.0pt;
  margin-right:0cm;
  margin-bottom:0cm;
  margin-left:0cm;
  line-height:107%;
  page-break-after:avoid;
  font-size:16.0pt;
  font-family:"Calibri Light",sans-serif;
  color:#2F5496;}
span.berschrift1Zchn
{mso-style-name:"Überschrift 1 Zchn";
  mso-style-link:"Überschrift 1";
  font-family:Helvetica;
  color:black;
  font-weight:bold;}
span.berschrift2Zchn
{mso-style-name:"Überschrift 2 Zchn";
  mso-style-link:"Überschrift 2";
  font-family:Helvetica;
  color:black;
  font-weight:bold;
  font-style:italic;}
p.Liste1, li.Liste1, div.Liste1
{mso-style-name:Liste1;
  margin-top:6.0pt;
  margin-right:0cm;
  margin-bottom:6.0pt;
  margin-left:0cm;
  text-indent:-14.2pt;
  text-autospace:none;
  font-size:10.0pt;
  font-family:"Arial",sans-serif;
  color:black;}
p.H1, li.H1, div.H1
{mso-style-name:H1;
  margin-top:12.0pt;
  margin-right:0cm;
  margin-bottom:6.0pt;
  margin-left:0cm;
  text-autospace:none;
  font-size:12.0pt;
  font-family:"Arial",sans-serif;
  color:black;
  font-weight:bold;}
span.KopfzeileZchn
{mso-style-name:"Kopfzeile Zchn";
  mso-style-link:Kopfzeile;
  font-family:"Arial",sans-serif;
  color:black;}
.MsoChpDefault
{font-size:11.0pt;
  font-family:"Segoe UI",sans-serif;}
.MsoPapDefault
{line-height:110%;}
/* Page Definitions */
@page WordSection1
{size:595.3pt 841.9pt;
  margin:70.85pt 70.85pt 2.0cm 70.85pt;}
div.WordSection1
{page:WordSection1;}
/* List Definitions */
ol
{margin-bottom:0cm;}
ul
{margin-bottom:0cm;}
</style>
