import { multiEntityLoading } from '../mixins/loading-states';
import Parse from 'parse';
/**
 * @class InstallationModule
 */
const TerminalModule = {
  namespaced: true,
  state: {
    ...multiEntityLoading.state,
    mountainProfile: null,
    branch: {
      name: 'Tourismuszentrum Schwarzwald',
    },
    mountainProfileQuestions: {},
    tours: null,
  },
  mutations: {
    ...multiEntityLoading.mutations,
    setMountainProfile(state, mountainProfile) {
      state.mountainProfile = mountainProfile;
    },
    setMountainProfileQuestions(state, mountainProfileQuestions) {
      state.mountainProfileQuestions = mountainProfileQuestions;
    },
    setResultTours(state, tours) {
      state.tours = tours;
    },
  },
  actions: {
    ...multiEntityLoading.actions,
    async loadAllMountainProfileQuestions({ commit }) {
      try {
        const quest = await new Parse.Query('MountainProfileQuestion')
          .include('answers')
          .include('categories')
          .include('type')
          .find();
        const o = {};
        for (let i = 0; i < quest.length; i++) {
          if (!o[quest[i].get('type').id]) {
            o[quest[i].get('type').id] = [];
            o[quest[i].get('type').id].push(quest[i]);
          } else {
            o[quest[i].get('type').id].push(quest[i]);
          }
        }
        commit('setMountainProfileQuestions', o);
      } catch (e) {
        console.log(e);
      }
    },
    async loadMatchingTours({ commit }, {id, sourceName}) {
      try {
        const data = await Parse.Cloud.run('searchTours', { id, sourceName }, {});
        if (data.error) {
          console.log(data.result);
        } else {
          commit('setResultTours', data.result);
        }
      } catch (e) {
        console.log(e);
      }
    },
  },
  getters: {
    ...multiEntityLoading.getters,
    mountainProfile: (state) => state.mountainProfile,
    branch: (state) => state.branch,
    mountainProfileQuestions: (state) => state.mountainProfileQuestions,
    tours: (state) => state.tours,
  },
};

export default TerminalModule;
