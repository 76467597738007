const StoreModules = {
  AUTH: 'auth',
  TOURS: 'tours',
  MOUNTAINPROFILES: 'mountain-profiles',
  SPORTS: 'sports',
  TOURPLANNINGMODULE: 'tour-planning',
  WEATHERAPI: 'weather-api',
  INITMODULE: 'init',
  PACKINGLISTSMODULE: 'packing-lists',
  TERMINALMODULE: 'terminal',
  SHORTINFOSMODULE: 'short-info',
  FEEDBACKMODULE: 'feedback',
};

export default StoreModules;
