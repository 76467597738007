import Parse from 'parse';
import { multiEntityLoading } from '../mixins/loading-states';
/**
 * @class InstallationModule
 */
const TourPlanningModule = {
  namespaced: true,
  state: {
    ...multiEntityLoading.state,
    specs: {},
    planning: false,
    planningSpecs: {},
    tours: null,
  },
  mutations: {
    ...multiEntityLoading.mutations,
    setSpecs(state, specs) {
      state.specs = specs;
    },
    setTours(state, tours) {
      state.tours = tours;
    },
    setPlanning(state, planning) {
      state.planning = planning;
    },
  },
  actions: {
    ...multiEntityLoading.actions,
    async searchTours({ commit, state }) {
      try {
        const data = await Parse.Cloud.run('searchTours', { specs: state.specs }, {});
        if (data.error) {
          console.log(data.result);
        } else {
          commit('setTours', data.result);
        }
      } catch (e) {
        console.log(e);
      }
    },
  },
  getters: {
    ...multiEntityLoading.getters,
    specs: (state) => state.specs,
    result: (state) => state.tours,
    planning: (state) => state.planning,
  },
};

export default TourPlanningModule;
