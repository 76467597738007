import Parse from 'parse';

import { multiEntityLoading } from '../mixins/loading-states';
/**
 * @class InstallationModule
 */
const PackingListsModule = {
  namespaced: true,
  state: {
    ...multiEntityLoading.state,
    packingList: null,
  },
  mutations: {
    ...multiEntityLoading.mutations,
    setPackingList(state, packingList) {
      state.packingList = packingList;
    },
  },
  actions: {
    ...multiEntityLoading.actions,
    async loadPackingList({ commit }, myTourId) {
      try {
        const packingList = await new Parse.Query('PackingList').get(myTourId);
        commit('setPackingList', packingList);
      } catch (e) {
        console.log(e);
      }
    },
    async addPackingList({ commit }, myTourId) {
      try {
        const data = await Parse.Cloud.run('addPackingList', { myTourId }, {});
        if (data.error) {
          console.log(data.result);
        } else {
          commit('setPackingList', data.result);
        }
      } catch (e) {
        console.log(e);
      }
    },
  },
  getters: {
    ...multiEntityLoading.getters,
    packingList: (state) => state.sports,
  },
};

export default PackingListsModule;
