import { multiEntityLoading } from '../mixins/loading-states';
/**
 * @class InstallationModule
 */
const ShortInfosModule = {
  namespaced: true,
  state: {
    ...multiEntityLoading.state,
    shortInfos: [
      {
        de: {
          content: 'In der Guida Learning App finden Sie Hilfsmittel um Ihren Rang zu verbessern!',
          readMore: 'https://guida-sa.de',
        },
      },
      {
        de: {
          content: 'Die Sonne geht im Osten auf',
        },
      },
    ],
  },
  mutations: {
    ...multiEntityLoading.mutations,
    setShortInfos(state, shortInfos) {
      state.shortInfos = shortInfos;
    },
  },
  actions: {
    ...multiEntityLoading.actions,
  },
  getters: {
    ...multiEntityLoading.getters,
    shortInfo: (state) => {
      if (state.shortInfos) {
        return state.shortInfos[Math.floor(Math.random() * state.shortInfos.length)];
      } else {
        return {
          de: {
            content:
              'In der Guida Learning App finden Sie Hilfsmittel um Ihren Rang zu verbessern!',
            readMore: 'https://guida-sa.de',
          },
        };
      }
    },
    shortInfos: (state) => state.shortInfos,
  },
};

export default ShortInfosModule;
